
  :global *,
  :global *::before,
  :global *::after {
    margin: 0;
    padding: 0;
  }
:global a {
    color: #9be7ff;
  }
:global a:visited {
    color: #64b5f6;
  }
:global nav a,
    :global nav a:visited {
      color: inherit;
    }
:global input[type="number"]::-webkit-inner-spin-button,
  :global input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
:global *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
:global *::-webkit-scrollbar-track {
    background: rgba($color: #fff, $alpha: 0.1);
  }
:global *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba($color: #fff, $alpha: 0.6);
  }
:global *::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
:global video:-webkit-full-screen {
    object-fit: contain;
  }
:global video:fullscreen {
    -o-object-fit: contain;
       object-fit: contain;
  }
:global .MuiInputLabel-root.Mui-disabled {
    opacity: 0.5;
  }
