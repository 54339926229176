.title {
  flex: 1;
  white-space: nowrap;
}

header .omniSearch {
    margin: 0 8px;
  }

.tree {
  height: 40px;
  width: auto;
  margin-left: -16px;
  margin-right: 8px;
  cursor: pointer;
}
