.languagePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.languagePicker .languageButton {
    min-width: 0;
    min-width: initial;
    margin: 0 4px;
    font-size: 18px;
    line-height: 24px;
    padding: 0 8px;
  }
.languagePicker .languageButton:first-of-type {
    margin-left: 12px;
  }
