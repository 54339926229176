.mainTitle {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.logo {
  height: 0.75em;
  margin-left: 0.3em;
  color: inherit;
}

.logo > path {
    fill: currentColor;
  }
