@use "src/partials/_breakpoints";

.version {
  padding: 8px 16px;
  font-weight: bold;
  font-size: 18px;
}

.localePicker {
  padding: 0 16px;
  margin-bottom: 8px;
}

.button {
  flex-shrink: 0;
}

.disabledMenuItem {
  opacity: 1 !important;
}

.disabledMenuItem small {
    opacity: 0.38;
  }

.snowflake {
  height: 1em;
}

.santaIcon {
  width: 1em;
}

.santaHat {
  margin-top: -10px;
  margin-right: -33px;
  width: 60px;
  height: auto;
  transform: scaleX(-1) rotate(-45deg);
  pointer-events: none;
}

.santaHatPopover {
  background: none !important;
  box-shadow: none !important;
  overflow: visible !important;
}
