.button {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-column-gap: 12px;
  color: white !important;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 8px 16px;
  width: 100%;
  align-items: center;
}

.selected {
  background-color: rgba(var(--primary-rgb), 0.4);
  border-radius: 16px;
}

.container {
  width: 100vw;
}

.buttons {
  padding: 12px;
}

.toolbar {
  justify-content: space-between;
  padding-right: 20px;
}
