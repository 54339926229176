.listItemButton {
  height: 48px;
  justify-content: center;
  padding: 0;
}
.listItemButton .listItemIcon {
    min-width: 0;
    padding: 0;
    margin: 0;
  }
