.cardHeader {
  padding-bottom: 0;
}

.cardContent {
  padding-top: 4px;
  padding-bottom: 2px;
}

.cardActions {
  padding-top: 2px;
  padding-bottom: 2px;
}

.secondLevel {
  margin-left: 24px;
}
