.punch,
.chips {
  --secondary: #cccccc;
  --error: #f24822;
  --success: #9cff33;
  --warning: #ff9500;
}

.punch {
  --primary: #9cff33;
  --primary-rgb: 156, 255, 51;
}

.chips {
  --primary: #c1afff;
  --primary-rgb: 250, 255, 0;
}
