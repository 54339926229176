.root {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 8px;
}

.slider {
  grid-column: 1 / span 2;
}

.singleColumn {
  grid-template-columns: 1fr;
}

.singleColumn .slider {
    grid-column: 1 / span 1;
  }

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item > :first-child {
    margin-right: 8px;
  }
