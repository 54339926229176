.root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: grid;
  grid-template-areas: "header header" "navigation main";
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 4px;
}

.root .header {
    grid-area: header;
  }

.root .header .toolbar {
      display: flex;
      flex-direction: row;
    }

.root .navigation {
    grid-area: navigation;
    border-right: 1px solid rgba(255, 255, 255, 0.12);
    overflow-y: auto;
  }

.root .main {
    grid-area: main;
    overflow: auto;
    position: relative;
  }

.root.right {
  grid-template-areas: "header header" "main navigation";
  grid-template-columns: 1fr min-content;
}

.mobileRoot {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

.mobileRoot .toolbar {
    padding: 0 8px !important;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-column-gap: 8px;
  }

.mobileRoot .main {
    flex: 1;
    overflow: auto;
    position: relative;
  }
